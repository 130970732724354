var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"stretch"}},[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.revenueEvents.title"))+" ")]),_c('v-data-table',{class:{ 'row-pointer': _vm.hasEditAccess },attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.isDictionariesLoading},on:{"click:row":_vm.handleClickByTableRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$lang('components.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.hasCreateAccess)?_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary","small":"","fab":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'revenue-events_new',
                    params: {
                      id: _vm.applicationId,
                    },
                  })}}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [(!_vm.eventNames.includes(item.token))?_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteDialog(item)}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}],null,true)})],1)],1)],1),(_vm.isConfirmDialogVisible)?_c('confirmation-dialog',{attrs:{"loading":_vm.isEventDeleting,"title":_vm.$lang('appAdmin.revenueEvents.deleteConfirmDialog.title'),"text":_vm.$lang('appAdmin.revenueEvents.deleteConfirmDialog.body'),"btn-text":_vm.$lang('btn.delete')},on:{"confirm":_vm.deleteRevenueEvent},model:{value:(_vm.isConfirmDialogVisible),callback:function ($$v) {_vm.isConfirmDialogVisible=$$v},expression:"isConfirmDialogVisible"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }