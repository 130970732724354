










































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";
import RevenueEventModel from "@/settings/models/revenue-events/RevenueEventModel";
import { Dictionary, DictionaryType } from "@/shared/models";
import RevenueEventRequestModel from "@/settings/models/revenue-events/RevenueEventRequestModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import { ReportHeader } from "@/reports/models";

@Component({
  components: {
    ConfirmationDialog,
  },
})
export default class RevenueEventsView extends mixins(AppSectionAccessMixin) {
  search = "";
  isConfirmDialogVisible = false;
  deletedEvent: RevenueEventModel | null = null;

  get headers(): Array<ReportHeader> {
    return [
      {
        text: this.$lang(`appAdmin.revenueEvents.tableHeaders.token`),
        align: "start",
        value: "token",
        sortable: false,
      },
      {
        text: this.$lang(`appAdmin.revenueEvents.tableHeaders.type`),
        align: "start",
        value: "type",
        sortable: false,
      },
      {
        text: this.$lang(`appAdmin.revenueEvents.tableHeaders.event`),
        align: "start",
        value: "event",
        sortable: false,
      },
      {
        text: this.$lang(`appAdmin.revenueEvents.tableHeaders.appVersionFrom`),
        align: "start",
        value: "appVersionFrom",
        sortable: false,
      },
      {
        text: this.$lang(`appAdmin.revenueEvents.tableHeaders.appVersionTo`),
        align: "start",
        value: "appVersionTo",
        sortable: false,
      },
      ...(this.hasDeleteAccess
        ? [
            {
              text: this.$lang(`appAdmin.revenueEvents.tableHeaders.actions`),
              align: "start",
              value: "actions",
              sortable: false,
            } as ReportHeader,
          ]
        : []),
    ];
  }

  get items(): Array<RevenueEventModel> {
    return this.$store.state.revenueEventsStore.revenueEvents;
  }

  get isDictionariesLoading(): boolean {
    return this.$store.state.dictionaryStore.loadingDictionary;
  }

  get eventNames(): Array<string> {
    return this.$store.state.dictionaryStore[
      DictionaryType.EVENT_NAMES
    ].values.map(({ value }: Dictionary) => value);
  }

  get isEventDeleting(): boolean {
    return this.$store.state.revenueEventsStore.isEventDeleting;
  }

  @Watch("applicationId", { immediate: true })
  fetchRevenueEvents() {
    this.$store.dispatch("loadRevenueEvents", this.applicationId);
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.revenueEvents.title")
    );

    if (
      !this.$store.state.dictionaryStore[DictionaryType.EVENT_NAMES].values
        .length
    ) {
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: [DictionaryType.EVENT_NAMES],
      });
    }
  }

  handleClickByTableRow(revenueEvent: RevenueEventModel) {
    if (!this.hasEditAccess) {
      return;
    }

    this.$router.push({
      name: "revenue-events_view",
      params: {
        id: this.applicationId,
        revenueEventsId: String(revenueEvent.id),
      },
    });
  }

  showDeleteDialog(item: RevenueEventModel) {
    this.deletedEvent = RevenueEventModel.ofSource(item);
    this.isConfirmDialogVisible = true;
  }

  async deleteRevenueEvent() {
    await this.$store.dispatch(
      "deleteRevenueEvent",
      new RevenueEventRequestModel(this.applicationId, this.deletedEvent?.id)
    );
    this.isConfirmDialogVisible = false;
    this.deletedEvent = null;
  }
}
